import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import {
  useForm as useReactHookForm,
  useFormContext as useReactHookFormContext,
  FormProvider as ReactHookFormProvider,
  FieldValues,
  UseFormReturn as UseReachHookFormReturn,
} from 'react-hook-form'

type Warnings<TFieldValues extends FieldValues> = {
  [K in keyof TFieldValues]?: string
}

type WarningContext<TFieldValues extends FieldValues> = {
  warnings: Warnings<TFieldValues>
  setWarning: (field: keyof TFieldValues, message: string) => void
  clearWarning: (field: keyof TFieldValues) => void
}

type UseFormReturn<TFieldValues extends FieldValues> =
  UseReachHookFormReturn<TFieldValues> & WarningContext<TFieldValues>

// it's not possible to pass the exact type while creating the context
// useContext must narrow down to the exact type
const WarningsContext = createContext<WarningContext<any> | null>(null)

export function useForm<TFieldValues extends FieldValues>(
  ...args: Parameters<typeof useReactHookForm<TFieldValues>>
): UseFormReturn<TFieldValues> {
  const formMethods = useReactHookForm<TFieldValues>(...args)
  const [warnings, setWarnings] = useState<Warnings<TFieldValues>>({})

  const setWarning = useCallback(
    (field: keyof TFieldValues, message: string) => {
      setWarnings((prev) => ({ ...prev, [field]: message }))
    },
    []
  )

  const clearWarning = useCallback((field: keyof TFieldValues) => {
    setWarnings((prev) => {
      const copy = { ...prev }
      delete copy[field]
      return copy
    })
  }, [])

  return useMemo(
    () => ({
      ...formMethods,
      warnings,
      setWarning,
      clearWarning,
    }),
    [clearWarning, formMethods, setWarning, warnings]
  )
}

export function useFormContext<
  TFieldValues extends FieldValues,
>(): UseFormReturn<TFieldValues> {
  const context = useReactHookFormContext<TFieldValues>()
  const warningsContext = useContext<WarningContext<TFieldValues> | null>(
    WarningsContext
  )

  if (!context || !warningsContext) {
    throw new Error('useFormContext must be used within a FormProvider.')
  }

  return useMemo(
    () => ({
      ...context,
      ...warningsContext,
    }),
    [context, warningsContext]
  )
}

export function FormProvider<TFieldValues extends FieldValues>({
  children,
  ...formMethods
}: {
  children: React.ReactNode
} & UseFormReturn<TFieldValues>) {
  const { warnings, setWarning, clearWarning } = formMethods

  return (
    <WarningsContext.Provider value={{ warnings, setWarning, clearWarning }}>
      <ReactHookFormProvider {...formMethods}>{children}</ReactHookFormProvider>
    </WarningsContext.Provider>
  )
}
