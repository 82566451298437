import React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import clsx from 'clsx'

import { Text } from '../Text/Text'

const message = cva('text-xs py-[1px]', {
  variants: {
    intent: {
      error: ['text-red-800'],
      warning: ['text-yellow-800'],
    },
  },
  defaultVariants: {
    intent: 'error',
  },
})

type MessageVariantProps = VariantProps<typeof message>

export type MessageProps = {
  className?: string
  label: React.ReactNode
} & MessageVariantProps

export const Message = ({
  label,
  className,
  intent = 'error',
}: MessageProps) => (
  <>
    {label ? (
      <Text weight="medium" className={clsx(className, message({ intent }))}>
        {label}
      </Text>
    ) : (
      <p className="h-5" />
    )}
  </>
)
