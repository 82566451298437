import { UpdateUserMutation, UpdateUserMutationVariables } from 'types/graphql'

import { TypedDocumentNode, useMutation } from '@redwoodjs/web'

const UPDATE_USER_MUTATION: TypedDocumentNode<
  UpdateUserMutation,
  UpdateUserMutationVariables
> = gql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`

export function useUpdateUser(
  options?: GraphQLMutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return useMutation(UPDATE_USER_MUTATION, options)
}
