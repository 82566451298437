import { SignupVariant } from 'types/index'

const KEY_SIGNUP_VARIANT = 'signupVariant'

export const localStorageUtils = {
  getSignupVariant: (): SignupVariant | null => {
    const signupVariant = localStorage.getItem(KEY_SIGNUP_VARIANT)
    if (signupVariant) {
      return signupVariant as SignupVariant
    }
    return null
  },
  setSignupVariant: (signupVariant: SignupVariant) => {
    localStorage.setItem(KEY_SIGNUP_VARIANT, signupVariant)
  },
  removeSignupVariant: () => {
    localStorage.removeItem(KEY_SIGNUP_VARIANT)
  },
}
