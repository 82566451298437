import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { cva } from 'class-variance-authority'
import clsx from 'clsx'
import Select, {
  components,
  ActionMeta,
  ControlProps,
  GroupBase,
  InputProps,
  MenuProps,
  OptionProps,
  ContainerProps as SelectContainerProps,
  Props as SelectProps,
  SingleValue as SingleValueOptionType,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select'
import SelectBaseProps from 'react-select/base'

import { reactSelectVariants, Option as BaseOption } from 'src/atoms'

type Option = BaseOption<string, string>

const controlVariants = cva(
  'flex items-center h-full justify-between relative w-full',
  {
    variants: {
      background: {
        white: 'bg-white',
        primary: 'bg-primary-300',
        transparent: '',
      },
      bordered: {
        true: 'border shadow-sm after:pointer-events-none after:absolute after:rounded-lg after:-inset-[1px] after:ring-inset after:focus-within:ring-2 after:focus-within:ring-primary-700',
        false: '',
      },
      rounded: {
        true: 'rounded-lg',
        false: '',
      },
      isFocused: {
        true: '',
        false: '',
      },
      isDisabled: {
        true: '!bg-gray-50 !border-gray-100',
        false: 'cursor-text',
      },
      size: {
        sm: 'min-h-7',
        lg: 'min-h-9',
        xl: 'min-h-9',
      },
    },
    compoundVariants: [
      {
        bordered: true,
        background: 'white',
        className: 'border-gray-500',
      },
      {
        bordered: true,
        background: 'primary',
        className: 'border-primary-700',
      },
    ],
  }
)

const Control = ({
  className,
  children,
  ...props
}: ControlProps<Option, false, GroupBase<Option>>) => (
  <components.Control
    className={clsx(
      controlVariants({
        background: props.selectProps.background,
        bordered: props.selectProps.bordered,
        rounded: props.selectProps.rounded,
        isFocused: props.isFocused,
        isDisabled: props.isDisabled,
        size: props.selectProps.size,
      }),
      className
    )}
    {...props}
  >
    {props.selectProps.startIcon}
    {children}
  </components.Control>
)

const DropdownIndicator = () => null

const IndicatorSeparator = () => null

const Input = ({
  className,
  ...props
}: InputProps<Option, false, GroupBase<Option>>) => (
  <components.Input className={clsx('px-3', className)} {...props} />
)

const Menu = ({
  className,
  ...props
}: MenuProps<Option, false, GroupBase<Option>>) => (
  <components.Menu
    className={clsx(
      'z-10 my-1 w-full rounded-lg bg-white drop-shadow-xl',
      className
    )}
    {...props}
  />
)

const optionVariants = cva(
  'min-h-9 py-2 px-3 select-none hover:bg-primary-200 !flex items-center w-full',
  {
    variants: {
      isFocused: {
        true: 'bg-primary-200',
        false: '',
      },
      isSelected: {
        true: '!bg-primary-700 text-white',
        false: '',
      },
    },
  }
)

const Option = ({
  className,
  ...props
}: OptionProps<Option, false, GroupBase<Option>>) => {
  return (
    <components.Option
      className={clsx(
        optionVariants({
          isFocused: props.isFocused,
          isSelected: props.isSelected,
        }),
        className
      )}
      {...props}
    />
  )
}

const selectContainerVariants = cva('w-full relative', {
  variants: {
    size: {
      sm: 'font-semibold text-sm min-h-7',
      lg: 'font-medium text-md min-h-9',
      xl: 'font-medium text-md min-h-9',
    },
    isDisabled: {
      true: 'text-gray-700 !pointer-events-auto cursor-not-allowed',
      false: 'text-gray-950',
    },
  },
})

const SelectContainer = ({
  className,
  ...props
}: SelectContainerProps<Option, false, GroupBase<Option>>) => (
  <components.SelectContainer
    className={clsx(
      selectContainerVariants({
        size: props.selectProps.size,
        isDisabled: props.selectProps.isDisabled,
      }),
      className
    )}
    {...props}
  />
)

const SingleValue = ({
  className,
  ...props
}: SingleValueProps<Option, false, GroupBase<Option>>) => (
  <components.SingleValue className={clsx('px-3', className)} {...props} />
)

const ValueContainer = ({
  className,
  ...props
}: ValueContainerProps<Option, false, GroupBase<Option>>) => (
  <components.ValueContainer className={clsx('gap-2', className)} {...props} />
)

export type DesiredPositionAutocompleteInputProps = Omit<
  SelectProps<Option, false, GroupBase<Option>>,
  'onChange' | 'value' | 'options' | 'onBlur'
> & {
  options: Option[]
  value?: string
  onChange: (value?: string) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => boolean | void
}

const otherOption = { value: 'Other', label: 'Other' }

export const DesiredPositionAutocompleteInput = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      classNames,
      components,
      styles,
      options: initialOptions,
      value,
      className,
      startIcon,
      rounded = true,
      bordered = true,
      background = 'white',
      size = 'lg',
      ...props
    }: DesiredPositionAutocompleteInputProps,
    ref?: React.Ref<SelectBaseProps<Option, false, GroupBase<Option>>>
  ) => {
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState<Option[]>([])

    const augmentedOptions = useMemo(
      () => [...initialOptions, otherOption],
      [initialOptions]
    )

    useEffect(() => {
      setOptions(augmentedOptions)
    }, [augmentedOptions])

    const handleInputChange = useCallback(
      (input: string): void => {
        const filteredOptions = augmentedOptions.filter(
          (option) =>
            option.label.toLowerCase().includes(input.toLowerCase()) ||
            option.value === otherOption.value
        )

        setOptions(filteredOptions)
        setInputValue(input)
      },
      [augmentedOptions]
    )

    const handleChange = useCallback(
      (
        newOption: SingleValueOptionType<Option>,
        actionMeta: ActionMeta<Option>
      ) => {
        if (actionMeta.action === 'clear') {
          return onChange('')
        }
        if (newOption) {
          onChange(newOption.value)
        }
      },
      [onChange]
    )

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (inputValue && options.length) {
          onChange(options[0].value)
        }
        onBlur?.(e)
      },
      [inputValue, onChange, onBlur, options]
    )

    const selectedOption = useMemo(() => {
      return options.find((option) => option.value === value)
    }, [options, value])

    return (
      <Select<Option, false>
        {...props}
        ref={ref}
        className={clsx(
          // this doesn't apply any classnames but can potentially
          reactSelectVariants({
            background,
            bordered,
            rounded,
            size,
          }),
          className
        )}
        isMulti={false}
        unstyled
        blurInputOnSelect={false}
        openMenuOnClick={false}
        openMenuOnFocus={false}
        // inputValue={}
        onInputChange={handleInputChange}
        filterOption={() => true}
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        value={selectedOption}
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator,
          Input,
          Menu,
          Option,
          SelectContainer,
          SingleValue,
          ValueContainer,
          ...components,
        }}
        styles={{
          control: () => ({}),
          option: () => ({}),
          ...styles,
        }}
        classNames={{
          placeholder: () => 'text-gray-700 px-3',
          noOptionsMessage: () => 'hidden',
          indicatorsContainer: () => 'me-1.5',
          ...classNames,
        }}
        // custom props
        startIcon={startIcon}
        background={background}
        bordered={bordered}
        rounded={rounded}
        size={size}
      />
    )
  }
)
