import { useCallback } from 'react'

import { TrashIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useFieldArray, UseFormWatch } from 'react-hook-form'
import * as yup from 'yup'

import { routes } from '@redwoodjs/router'

import {
  Button,
  Card,
  CheckboxField,
  CheckboxGroup,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  PlusIcon,
  RequiredAsterisk,
  Text,
} from 'src/atoms'
import { useBatchCreateWorkExperiencesMutation } from 'src/hooks/mutations/useBatchCreateWorkExperiences'
import { dates } from 'src/lib/utils/dates/dates'
import {
  RHFInput,
  RHFBulletListTextarea,
  RHFCitySelect,
  RHFCheckbox,
  RHFSelectMonth,
  RHFSelectYear,
  useForm,
  FormProvider,
} from 'src/reactHookForm'
import { WorkExperienceSchema, workExperienceSchema } from 'src/schemas'

import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'

const defaultValues: WorkExperienceSchema = {
  title: '',
  company: '',
  location: '',
  startMonth: '',
  startYear: '',
  endMonth: '',
  endYear: '',
  currentPosition: false,
  descriptions: [],
}

const WorkExperience = ({
  index,
  handleCurrentPositionChange,
  remove,
  watch,
}: {
  index: number
  handleCurrentPositionChange: ({
    checked,
    index,
  }: {
    checked: boolean
    index: number
  }) => void
  remove: (index: number) => void
  watch: UseFormWatch<{ workExperiences?: WorkExperienceSchema[] }>
}) => {
  const [watchCurrentPosition, watchTitle, watchCompany] = watch<
    [
      `workExperiences.${number}.currentPosition`,
      `workExperiences.${number}.title`,
      `workExperiences.${number}.company`,
    ]
  >([
    `workExperiences.${index}.currentPosition`,
    `workExperiences.${index}.title`,
    `workExperiences.${index}.company`,
  ])

  return (
    <Card className="flex flex-col gap-4 !p-[18px] md:gap-6 !bg-gray-100">
      <div className="flex items-center justify-between gap-2 w-full">
        <div>
          {watchTitle && (
            <Text weight="semibold" className="text-medium">
              {watchTitle}
            </Text>
          )}
          {watchTitle && watchCompany && (
            <Text weight="medium" className="text-sm text-gray-900">
              {watchCompany}
            </Text>
          )}
        </div>
        <Button onClick={() => remove(index)} plain>
          <TrashIcon className="!h-5 !w-5 text-gray-950" />
        </Button>
      </div>
      <Fieldset>
        <FieldGroup>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
            <Field>
              <Label>
                Job title
                <RequiredAsterisk />
              </Label>
              <RHFInput<WorkExperienceSchema>
                // TODO: Better generic type.
                name={
                  `workExperiences.${index}.title` as keyof WorkExperienceSchema
                }
                placeholder="Enter job title"
              />
            </Field>
            <Field>
              <Label>
                Company name
                <RequiredAsterisk />
              </Label>
              <RHFInput<WorkExperienceSchema>
                // TODO: Better generic type.
                name={
                  `workExperiences.${index}.company` as keyof WorkExperienceSchema
                }
                placeholder="Enter company name"
              />
            </Field>
          </div>

          <Field>
            <Label>
              Location
              <RequiredAsterisk />
            </Label>
            <RHFCitySelect<WorkExperienceSchema>
              // TODO: Better generic type.
              name={
                `workExperiences.${index}.location` as keyof WorkExperienceSchema
              }
              placeholder="Location"
            />
          </Field>

          <div className="grid grid-cols-2 gap-2">
            <Field>
              <Label>
                From
                <RequiredAsterisk />
              </Label>
              <RHFSelectMonth<WorkExperienceSchema>
                // TODO: Better generic type.
                name={
                  `workExperiences.${index}.startMonth` as keyof WorkExperienceSchema
                }
                placeholder="Month"
              />
            </Field>
            <Field>
              <Label className="invisible">Start Year</Label>
              <RHFSelectYear<WorkExperienceSchema>
                // TODO: Better generic type.
                name={
                  `workExperiences.${index}.startYear` as keyof WorkExperienceSchema
                }
                placeholder="Year"
              />
            </Field>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Field>
              <Label>
                To
                {!watchCurrentPosition && <RequiredAsterisk />}
              </Label>
              <RHFSelectMonth<WorkExperienceSchema>
                // TODO: Better generic type.
                name={
                  `workExperiences.${index}.endMonth` as keyof WorkExperienceSchema
                }
                placeholder="Month"
                disabled={watchCurrentPosition}
                isClearable
              />
            </Field>
            <Field>
              <Label className="invisible">End Year</Label>
              <RHFSelectYear<WorkExperienceSchema>
                // TODO: Better generic type.
                name={
                  `workExperiences.${index}.endYear` as keyof WorkExperienceSchema
                }
                placeholder="Year"
                disabled={watchCurrentPosition}
                isClearable
              />
            </Field>
          </div>

          <CheckboxGroup>
            <CheckboxField className="flex flex-row flex-nowrap items-end">
              <RHFCheckbox<WorkExperienceSchema>
                // TODO: Better generic type.
                name={
                  `workExperiences.${index}.currentPosition` as keyof WorkExperienceSchema
                }
                onChange={(checked) => {
                  handleCurrentPositionChange({
                    checked,
                    index,
                  })
                }}
              />
              <Label className="!mb-0">I currently work here</Label>
            </CheckboxField>
          </CheckboxGroup>
        </FieldGroup>
      </Fieldset>

      <div className="flex flex-col md:flex-row gap-3 h-[480px]">
        <Field className="md:w-1/2 h-full flex flex-col overflow-scroll">
          <Text weight="semibold" className="text-sm mb-2">
            Responsibilities
          </Text>
          <RHFBulletListTextarea<WorkExperienceSchema>
            // TODO: Better generic type.
            name={
              `workExperiences.${index}.descriptions` as keyof WorkExperienceSchema
            }
            labelClassName="w-full rounded-lg flex flex-col h-full overflow-scroll"
            placeholder="Describe your position and any significant accomplishments"
          />
        </Field>
      </div>
    </Card>
  )
}

export function WorkExperiencesStep({
  handleNext,
  isLastStep,
  workExperiences,
}: {
  handleNext: () => void
  isLastStep: boolean
  workExperiences: WorkExperienceSchema[]
}) {
  const [batchCreateWorkExperiences, { loading }] =
    useBatchCreateWorkExperiencesMutation()

  const methods = useForm({
    defaultValues: { workExperiences },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        workExperiences: yup.array().of(workExperienceSchema),
      })
    ),
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'workExperiences',
  })

  const handleCurrentPositionChange = useCallback(
    ({ checked, index }: { checked: boolean; index: number }) => {
      methods.setValue(`workExperiences.${index}.currentPosition`, checked)
      methods.trigger(`workExperiences.${index}.endMonth`)
      methods.trigger(`workExperiences.${index}.endYear`)
    },
    [methods]
  )

  const handleSubmit: SubmitHandler<{
    workExperiences: WorkExperienceSchema[]
  }> = useCallback(
    async ({ workExperiences }) => {
      for (let i = 0; i < workExperiences.length; i++) {
        const workExperience = workExperiences[i]
        const isStartDateBeforeEndDate =
          dates.isStartDateBeforeOrEqualToEndDate(
            workExperience.startMonth,
            workExperience.startYear,
            workExperience.endMonth,
            workExperience.endYear
          )

        if (!isStartDateBeforeEndDate) {
          methods.setError(`workExperiences.${i}.startMonth`, {
            type: 'custom',
            message: "Start date can't come after end date",
          })

          // Return early since there is a validation error.
          return
        }
      }

      const payload = workExperiences.map((workExperience) => {
        return {
          title: workExperience.title || null,
          company: workExperience.company || null,
          location: workExperience.location || null,
          startMonth: workExperience.startMonth || null,
          startYear: workExperience.startYear || null,
          endMonth: workExperience.currentPosition
            ? null
            : workExperience.endMonth || null,
          endYear: workExperience.currentPosition
            ? 'Present'
            : workExperience.endYear || null,
          currentPosition: workExperience.currentPosition || false,
          descriptions: workExperience.descriptions?.filter(
            (description) => description !== undefined && description !== null
          ),
        }
      })

      await batchCreateWorkExperiences({
        variables: {
          inputs: payload,
        },
        onCompleted: () => {
          if (isLastStep) {
            // Hard navigate to the profile page to refetch all of the relevant queries and close the modal.
            window.location.replace(routes.profile())
          } else {
            handleNext()
          }
        },
      })
    },
    [batchCreateWorkExperiences, handleNext, isLastStep, methods]
  )

  return (
    <div className="flex flex-col gap-6">
      <Header
        title="Did we get your work experience right?"
        description="Double-check each experience below to make sure we got your information correct."
      />
      <FormProvider {...methods}>
        <form>
          <div className="flex flex-col gap-6">
            {fields.map((field, index) => {
              return (
                <WorkExperience
                  key={field.id}
                  index={index}
                  handleCurrentPositionChange={handleCurrentPositionChange}
                  remove={remove}
                  watch={methods.watch}
                />
              )
            })}
          </div>
        </form>
      </FormProvider>
      <Button
        color="white"
        className="w-full"
        onClick={() => append(defaultValues)}
      >
        <PlusIcon className="h-5 w-5" /> Add
      </Button>
      <Footer
        handleNext={async () => {
          await methods.handleSubmit(handleSubmit)()
        }}
        isLastStep={isLastStep}
        loading={loading}
      />
    </div>
  )
}
