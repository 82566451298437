import React from 'react'

import { FieldValues, Path, Controller, PathValue } from 'react-hook-form'

import { Checkbox, CheckboxProps, Message } from 'src/atoms'

import { useFormContext } from './form'

export type RHFCheckboxProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  onChange?: (checked: boolean) => boolean | void
  onBlur?: (e: React.FocusEvent<HTMLSpanElement>) => boolean | void
  clearErrorOnChange?: boolean
  clearWarningOnChange?: boolean
} & Omit<CheckboxProps, 'value' | 'onChange' | 'onBlur'>

export const RHFCheckbox = <TFieldValues extends FieldValues>({
  name,
  description,
  onChange: handleChangeProps,
  onBlur: handleBlurProps,
  clearErrorOnChange,
  clearWarningOnChange,
  ...props
}: RHFCheckboxProps<TFieldValues>) => {
  const { control, clearErrors, setValue, warnings, clearWarning } =
    useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange: handleChangeField,
          onBlur: handleBlurField,
          ...field
        },
        fieldState: { error },
      }) => {
        const handleChange = (checked: boolean) => {
          const stopPropagation = handleChangeProps?.(checked)
          if (stopPropagation) {
            return
          }
          if (clearWarningOnChange) {
            clearWarning(name)
          }
          if (clearErrorOnChange) {
            clearErrors(name)
            setValue(
              name,
              checked as PathValue<TFieldValues, Path<TFieldValues>>
            )
            return
          }
          handleChangeField(checked)
        }

        const handleBlur = (e: React.FocusEvent<HTMLSpanElement>) => {
          const stopPropagation = handleBlurProps?.(e)
          if (stopPropagation) {
            return
          }
          handleBlurField()
        }

        const messageLabel = error?.message || warnings[name]
        const messageIntent = error?.message ? 'error' : 'warning'
        return (
          <>
            <Checkbox
              onBlur={handleBlur}
              onChange={handleChange}
              {...field}
              {...props}
              checked={field.value}
            />
            {description}
            <Message
              className="ms-1.5"
              label={messageLabel}
              intent={messageIntent}
            />
          </>
        )
      }}
    />
  )
}
