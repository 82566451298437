import { useCallback } from 'react'

import { TrashIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  SubmitHandler,
  useFieldArray,
  UseFieldArrayRemove,
  UseFormWatch,
} from 'react-hook-form'
import * as yup from 'yup'

import { routes } from '@redwoodjs/router'

import {
  Button,
  Card,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  PlusIcon,
  RequiredAsterisk,
  Text,
} from 'src/atoms'
import { Footer } from 'src/components/ResumeImport/CompleteProfile/steps/Footer'
import { Header } from 'src/components/ResumeImport/CompleteProfile/steps/Header'
import { useBatchCreateCertificationsMutation } from 'src/hooks/mutations/useBatchCreateCertifications'
import { dates } from 'src/lib/utils/dates/dates'
import {
  RHFInput,
  RHFSelectMonth,
  RHFSelectYear,
  FormProvider,
  useForm,
} from 'src/reactHookForm'
import { certificationSchema, CertificationSchema } from 'src/schemas'

// Allow end year to be up to 30 years from the current year.
// This is based on the maximum expiration we've seen so far
// plus a lot of buffer! However, we can adjust as needed.
const endYear = new Date().getFullYear() + 30

const defaultValues: CertificationSchema = {
  name: '',
  organization: '',
  startMonth: '',
  startYear: '',
  endMonth: '',
  endYear: '',
  credentialId: '',
  credentialUrl: '',
}

function Certification({
  index,
  remove,
  watch,
}: {
  index: number
  remove: UseFieldArrayRemove
  watch: UseFormWatch<{ certifications?: CertificationSchema[] }>
}) {
  const [watchName, watchCredentialId] = watch<
    [`certifications.${number}.name`, `certifications.${number}.credentialId`]
  >([`certifications.${index}.name`, `certifications.${index}.credentialId`])

  return (
    <Card className="flex flex-col gap-4 !p-[18px] md:gap-6 !bg-gray-100">
      <div className="flex items-center justify-between gap-2 shrink-0 w-full">
        <div>
          {watchName && (
            <Text weight="semibold" className="text-medium">
              {watchName}
            </Text>
          )}
          {watchName && watchCredentialId && (
            <Text weight="medium" className="text-sm text-gray-900">
              Credential ID: {watchCredentialId}
            </Text>
          )}
        </div>
        <Button onClick={() => remove(index)} plain>
          <TrashIcon className="!h-5 !w-5 text-gray-950" />
        </Button>
      </div>
      <Fieldset>
        <FieldGroup>
          <Field>
            <Label>
              Certification / License Name
              <RequiredAsterisk />
            </Label>
            <RHFInput<CertificationSchema>
              placeholder="Ex: Forklift Certification"
              name={
                `${`certifications.${index}.name`}` as keyof CertificationSchema
              }
            />
          </Field>
          <Field>
            <Label>Organization Name</Label>
            <RHFInput<CertificationSchema>
              placeholder="Ex: OSHA"
              name={
                `${`certifications.${index}.organization`}` as keyof CertificationSchema
              }
            />
          </Field>

          <div className="grid grid-cols-2 gap-2">
            <Field>
              <Label>Issue Date</Label>
              <RHFSelectMonth<CertificationSchema>
                name={
                  `${`certifications.${index}.startMonth`}` as keyof CertificationSchema
                }
                placeholder="Ex: March"
                isClearable
              />
            </Field>
            <Field>
              <Label className="invisible">Issue Year</Label>
              <RHFSelectYear<CertificationSchema>
                name={
                  `${`certifications.${index}.startYear`}` as keyof CertificationSchema
                }
                placeholder="Ex: 2024"
                isClearable
              />
            </Field>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Field>
              <Label>Expiration Date</Label>
              <RHFSelectMonth<CertificationSchema>
                name={
                  `${`certifications.${index}.endMonth`}` as keyof CertificationSchema
                }
                placeholder="Ex: July"
                isClearable
              />
            </Field>
            <Field>
              <Label className="invisible">Exp. Year</Label>
              <RHFSelectYear<CertificationSchema>
                name={
                  `${`certifications.${index}.endYear`}` as keyof CertificationSchema
                }
                placeholder="Ex: 2029"
                endYear={endYear}
                isClearable
              />
            </Field>
          </div>

          <Field>
            <Label>Credential ID</Label>
            <RHFInput<CertificationSchema>
              placeholder="Ex: 123456789"
              name={
                `${`certifications.${index}.credentialId`}` as keyof CertificationSchema
              }
            />
          </Field>
          <Field>
            <Label>Credential URL</Label>
            <RHFInput<CertificationSchema>
              placeholder="Ex: https://www.example.com"
              name={
                `${`certifications.${index}.credentialUrl`}` as keyof CertificationSchema
              }
            />
          </Field>
        </FieldGroup>
      </Fieldset>
    </Card>
  )
}

export function CertificationsStep({
  handleNext,
  isLastStep,
  certifications,
}: {
  handleNext: () => void
  isLastStep: boolean
  certifications: CertificationSchema[]
}) {
  const [batchCreateCertifications, { loading }] =
    useBatchCreateCertificationsMutation()

  const methods = useForm({
    defaultValues: { certifications },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        certifications: yup.array().of(certificationSchema),
      })
    ),
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'certifications',
  })

  const handleSubmit: SubmitHandler<{
    certifications: CertificationSchema[]
  }> = useCallback(
    async ({ certifications }) => {
      for (let i = 0; i < certifications.length; i++) {
        const certification = certifications[i]
        const isStartDateBeforeEndDate =
          dates.isStartDateBeforeOrEqualToEndDate(
            certification.startMonth,
            certification.startYear,
            certification.endMonth,
            certification.endYear
          )

        if (!isStartDateBeforeEndDate) {
          methods.setError(`certifications.${i}.startMonth`, {
            type: 'custom',
            message: "Start date can't come after end date",
          })

          // Return early since there is a validation error.
          return
        }
      }

      const payload = certifications.map((certification) => ({
        name: certification.name || null,
        organization: certification.organization || null,
        startMonth: certification.startMonth || null,
        startYear: certification.startYear || null,
        endMonth: certification.endMonth || null,
        endYear: certification.endYear || null,
        credentialId: certification.credentialId || null,
        credentialUrl: certification.credentialUrl || null,
      }))

      await batchCreateCertifications({
        variables: {
          inputs: payload,
        },
        onCompleted: () => {
          if (isLastStep) {
            // Hard navigate to the profile page to refetch all of the relevant queries and close the modal.
            window.location.replace(routes.profile())
          } else {
            handleNext()
          }
        },
      })
    },
    [batchCreateCertifications, handleNext, isLastStep, methods]
  )

  return (
    <div className="flex flex-col gap-6">
      <Header title="Did we get your certifications right?" />
      <FormProvider {...methods}>
        <form>
          <div className="flex flex-col gap-6">
            {fields.map((field, index) => {
              return (
                <Certification
                  key={field.id}
                  index={index}
                  remove={remove}
                  watch={methods.watch}
                />
              )
            })}
          </div>
        </form>
      </FormProvider>
      <Button
        color="white"
        className="w-full"
        onClick={() => append(defaultValues)}
      >
        <PlusIcon className="h-5 w-5" /> Add
      </Button>
      <Footer
        handleNext={async () => {
          await methods.handleSubmit(handleSubmit)()
        }}
        isLastStep={isLastStep}
        loading={loading}
      />
    </div>
  )
}
