import React from 'react'

import clsx from 'clsx'
import { useFormContext, Controller } from 'react-hook-form'
import {
  YearsOfExperienceRangesQuery,
  YearsOfExperienceRangesQueryVariables,
} from 'types/graphql'

import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import {
  Field,
  Label,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Message,
} from 'src/atoms'
import { RequiredKeys } from 'src/types'

// * BE should return ranges sorted by ordinal in ascending order.
export const QUERY: TypedDocumentNode<
  YearsOfExperienceRangesQuery,
  YearsOfExperienceRangesQueryVariables
> = gql`
  query YearsOfExperienceRangesQuery {
    yearsOfExperienceRanges {
      id
      range
    }
  }
`

/**
 * Used to connect Radio inputs.
 */
const Line = ({ disabled }: { disabled?: boolean }) => (
  <div
    // IMPORTANT: Please make sure these colors are kept in sync with `Radio.tsx`.
    className={`w-full ${disabled ? 'bg-zinc-950/25' : 'bg-zinc-950/15'} h-[1px]`}
  />
)

// * We do not want to show anything while loading since this is a special radio input.
export const Loading = () => null

// * Removed `Empty` as it should never be used.

export const Failure = ({ error }: CellFailureProps) => (
  <>{error ? <div className="rw-cell-error">{error.message}</div> : null}</>
)

type IntrinsicProps = RequiredKeys<RadioGroupProps, 'name'>

export const Success = ({
  yearsOfExperienceRanges,
  updating: _updating,
  queryResult: _queryResult,
  name,
  ...radioGroupProps
}: CellSuccessProps<
  YearsOfExperienceRangesQuery,
  YearsOfExperienceRangesQueryVariables
> &
  IntrinsicProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ...field }, fieldState: { error } }) => {
        return (
          <>
            <RadioGroup {...radioGroupProps} {...field} value={value}>
              {/* NOTE: Important to maintain `mb-[20px] as that accounts for the absolutely positioned labels. */}
              <div className="flex items-center justify-between mb-[20px]">
                {yearsOfExperienceRanges.map(
                  (yearsOfExperienceRange, index) => {
                    return (
                      <React.Fragment key={yearsOfExperienceRange.id}>
                        {index !== 0 && (
                          <Line disabled={radioGroupProps.disabled} />
                        )}
                        <Field
                          className="flex flex-col items-center"
                          key={yearsOfExperienceRange.id}
                        >
                          <Radio value={yearsOfExperienceRange.id} />
                          <div className="relative">
                            <Label
                              className={clsx(
                                'absolute top-[2px] translate-x-[-50%] w-max',
                                value === yearsOfExperienceRange.id &&
                                  '!font-bold'
                              )}
                            >
                              {yearsOfExperienceRange.range}
                            </Label>
                          </div>
                        </Field>
                      </React.Fragment>
                    )
                  }
                )}
              </div>
            </RadioGroup>
            <Message label={error?.message} />
          </>
        )
      }}
    />
  )
}
