import { useCallback } from 'react'

import { TrashIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  SubmitHandler,
  useFieldArray,
  UseFieldArrayRemove,
  UseFormWatch,
} from 'react-hook-form'
import * as yup from 'yup'

import { routes } from '@redwoodjs/router'

import {
  Button,
  Card,
  CheckboxField,
  CheckboxGroup,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  PlusIcon,
  RequiredAsterisk,
  Text,
} from 'src/atoms'
import { Footer } from 'src/components/ResumeImport/CompleteProfile/steps/Footer'
import { Header } from 'src/components/ResumeImport/CompleteProfile/steps/Header'
import { useBatchCreateEducationsMutation } from 'src/hooks/mutations/useBatchCreateEducations'
import { dates } from 'src/lib/utils/dates/dates'
import {
  RHFCheckbox,
  RHFInput,
  RHFCitySelect,
  RHFSelectMonth,
  RHFSelectYear,
  FormProvider,
  useForm,
} from 'src/reactHookForm'
import { educationSchema, EducationSchema } from 'src/schemas'

const defaultValues: EducationSchema = {
  current: false,
  degree: '',
  endMonth: '',
  endYear: '',
  location: '',
  school: '',
  startMonth: '',
  startYear: '',
}

function Education({
  index,
  handleCurrentChange,
  remove,
  watch,
}: {
  index: number
  handleCurrentChange: ({
    checked,
    index,
  }: {
    checked: boolean
    index: number
  }) => void
  remove: UseFieldArrayRemove
  watch: UseFormWatch<{ educations?: EducationSchema[] }>
}) {
  const [watchCurrent, watchSchool] = watch<
    [`educations.${number}.current`, `educations.${number}.school`]
  >([`educations.${index}.current`, `educations.${index}.school`])

  return (
    <Card className="flex flex-col gap-4 !p-[18px] md:gap-6 !bg-gray-100">
      <div className="flex items-center justify-between gap-2 shrink-0 w-full">
        <div>
          {watchSchool && (
            <Text weight="semibold" className="text-medium">
              {watchSchool}
            </Text>
          )}
        </div>
        <Button onClick={() => remove(index)} plain>
          <TrashIcon className="!h-5 !w-5 text-gray-950" />
        </Button>
      </div>
      <Fieldset>
        <FieldGroup>
          <Field>
            <Label>
              School Name
              <RequiredAsterisk />
            </Label>
            <RHFInput<EducationSchema>
              placeholder="Enter school name"
              type="text"
              name={`educations.${index}.school` as keyof EducationSchema}
            />
          </Field>
          <Field>
            <Label>
              Degree
              <RequiredAsterisk />
            </Label>
            <RHFInput<EducationSchema>
              placeholder="Enter degree title"
              type="text"
              name={`educations.${index}.degree` as keyof EducationSchema}
            />
          </Field>

          <Field>
            <Label>
              Location
              <RequiredAsterisk />
            </Label>
            <RHFCitySelect<EducationSchema>
              name={`educations.${index}.location` as keyof EducationSchema}
              placeholder="Location"
            />
          </Field>

          <div className="grid grid-cols-2 gap-2">
            <Field>
              <Label>
                From
                <RequiredAsterisk />
              </Label>
              <RHFSelectMonth<EducationSchema>
                name={`educations.${index}.startMonth` as keyof EducationSchema}
                placeholder="Month"
              />
            </Field>
            <Field>
              <Label className="invisible">Start Year</Label>
              <RHFSelectYear<EducationSchema>
                name={`educations.${index}.startYear` as keyof EducationSchema}
                placeholder="Year"
              />
            </Field>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Field>
              <Label>
                To
                {!watchCurrent && <RequiredAsterisk />}
              </Label>
              <RHFSelectMonth<EducationSchema>
                name={`educations.${index}.endMonth` as keyof EducationSchema}
                placeholder="Month"
                disabled={watchCurrent}
                isClearable
              />
            </Field>
            <Field>
              <Label className="invisible">End Year</Label>
              <RHFSelectYear<EducationSchema>
                name={`educations.${index}.endYear` as keyof EducationSchema}
                placeholder="Year"
                disabled={watchCurrent}
                isClearable
              />
            </Field>
          </div>

          <CheckboxGroup>
            <CheckboxField className="flex flex-row flex-nowrap items-end">
              <RHFCheckbox<EducationSchema>
                name={`educations.${index}.current` as keyof EducationSchema}
                onChange={(checked) =>
                  handleCurrentChange({
                    checked,
                    index,
                  })
                }
              />
              <Label className="!mb-0">
                I currently attend this institution
              </Label>
            </CheckboxField>
          </CheckboxGroup>
        </FieldGroup>
      </Fieldset>
    </Card>
  )
}

export function EducationsStep({
  educations,
  handleNext,
  isLastStep,
}: {
  educations: EducationSchema[]
  handleNext: () => void
  isLastStep: boolean
}) {
  const [batchCreateEducations, { loading }] =
    useBatchCreateEducationsMutation()

  const methods = useForm({
    defaultValues: { educations },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        educations: yup.array().of(educationSchema),
      })
    ),
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'educations',
  })

  const handleSubmit: SubmitHandler<{
    educations: EducationSchema[]
  }> = useCallback(
    async ({ educations }) => {
      for (let i = 0; i < educations.length; i++) {
        const education = educations[i]
        const isStartDateBeforeEndDate =
          dates.isStartDateBeforeOrEqualToEndDate(
            education.startMonth,
            education.startYear,
            education.endMonth,
            education.endYear
          )

        if (!isStartDateBeforeEndDate) {
          methods.setError(`educations.${i}.startMonth`, {
            type: 'custom',
            message: "Start date can't come after end date",
          })

          // Return early since there is a validation error.
          return
        }
      }

      const payload = educations.map((education) => ({
        current: education.current || false,
        degree: education.degree || null,
        endMonth: education.endMonth || null,
        endYear: education.endYear || null,
        location: education.location || null,
        school: education.school || null,
        startMonth: education.startMonth || null,
        startYear: education.startYear || null,
      }))

      await batchCreateEducations({
        variables: {
          inputs: payload,
        },
        onCompleted: () => {
          if (isLastStep) {
            // Hard navigate to the profile page to refetch all of the relevant queries and close the modal.
            window.location.replace(routes.profile())
          } else {
            handleNext()
          }
        },
      })
    },
    [batchCreateEducations, handleNext, isLastStep, methods]
  )

  const handleCurrentChange = useCallback(
    ({ checked, index }: { checked: boolean; index: number }) => {
      methods.setValue<`educations.${number}.current`>(
        `educations.${index}.current`,
        checked
      )
      methods.trigger(`educations.${index}.endMonth`)
      methods.trigger(`educations.${index}.endYear`)
    },
    [methods]
  )

  return (
    <div className="flex flex-col gap-6">
      <Header title="Did we get your education right?" />

      <FormProvider {...methods}>
        <form>
          <div className="flex flex-col gap-6">
            {fields.map((field, index) => {
              return (
                <Education
                  key={field.id}
                  index={index}
                  handleCurrentChange={handleCurrentChange}
                  remove={remove}
                  watch={methods.watch}
                />
              )
            })}
          </div>
        </form>
      </FormProvider>
      <Button
        color="white"
        className="w-full"
        onClick={() => append(defaultValues)}
      >
        <PlusIcon className="h-5 w-5" /> Add
      </Button>
      <Footer
        handleNext={async () => {
          await methods.handleSubmit(handleSubmit)()
        }}
        isLastStep={isLastStep}
        loading={loading}
      />
    </div>
  )
}
