import Mixpanel, { Dict } from 'mixpanel-browser'
import { SignupVariant } from 'types/index'

import { HomePageCtaCarouselCardType, JobCardSource } from 'src/lib/constants'
import { ScreenSize } from 'src/screenSize'

// Type related to the properties of a job typically sent to Mixpanel.
type MixpanelJobProperties = {
  type: 'external' | 'internal'
  jobCardSource: JobCardSource
  company: string | null
  jobUrl: string | null
  jobName: string | null
  jobFullAddress: string | null
  jobCityAndState: string | null
  jobSaved: boolean | null
}

export const mixpanelUtils = {
  calculateMixpanelJobPropertiesForExternalJob: ({
    job,
    jobCardSource,
  }: {
    job: {
      title?: string | null
      company?: string | null
      applyUrl?: string | null
      address?: string | null
      formatted_address_data?: string | null
      saved?: boolean | null
    }
    jobCardSource: JobCardSource
  }): MixpanelJobProperties => ({
    type: 'external',
    jobCardSource,
    company: job.company || null,
    jobUrl: job.applyUrl || null,
    jobName: job.title || null,
    jobFullAddress: job.address || null,
    jobCityAndState:
      job.formatted_address_data && job.formatted_address_data !== 'undefined'
        ? job.formatted_address_data
        : null,
    jobSaved: job.saved || (job.saved === false ? false : null),
  }),

  calculateMixpanelJobPropertiesForInternalJob: ({
    job,
    jobCardSource,
  }: {
    job: {
      id: string
      company: {
        name: string
      }
      title: string
      address: string
      city: string
      state: string
      zipCode: string
    }
    jobCardSource: JobCardSource
  }): MixpanelJobProperties => ({
    type: 'internal',
    jobCardSource,
    company: job.company.name,
    jobUrl: `laborup-jobs/${job.id}`,
    jobName: job.title,
    jobFullAddress: `${job.address}, ${job.city}, ${job.state} ${job.zipCode}`,
    jobCityAndState: `${job.city}, ${job.state}`,
    jobSaved: null, // We just put `null` here because there is no notion of saved jobs for Laborup jobs.
  }),

  createSignupHashFromNameAndHash: ({
    dynamicSignupVariantHash,
    dynamicSignupVariantName,
  }: {
    dynamicSignupVariantHash: string
    dynamicSignupVariantName: string
  }) => {
    return `${dynamicSignupVariantName}+${dynamicSignupVariantHash}`
  },

  trackHomePageCtaCarouselCardClick: ({
    type,
  }: {
    type: HomePageCtaCarouselCardType
  }) => {
    try {
      Mixpanel.track('home_page_cta_carousel_card_click', {
        type,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobApplyClick: ({
    type,
    company,
    jobCardSource,
    jobUrl,
    jobName,
    jobFullAddress,
    jobCityAndState,
    jobSaved,
  }: MixpanelJobProperties) => {
    try {
      Mixpanel.track('job_apply_click', {
        type,
        company,
        job_card_source: jobCardSource,
        job_url: jobUrl,
        job_name: jobName,
        job_full_address: jobFullAddress,
        job_city_and_state: jobCityAndState,
        job_saved: jobSaved,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobCardClick: ({
    type,
    company,
    jobCardSource,
    jobUrl,
    jobName,
    jobFullAddress,
    jobCityAndState,
    jobSaved,
  }: MixpanelJobProperties) => {
    try {
      Mixpanel.track('job_card_click', {
        type,
        company,
        job_card_source: jobCardSource,
        job_url: jobUrl,
        job_name: jobName,
        job_full_address: jobFullAddress,
        job_city_and_state: jobCityAndState,
        job_saved: jobSaved,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobInterviewClick: ({ jobId }: { jobId: string }) => {
    try {
      Mixpanel.track('job_interview_click', { jobId })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobInterviewBookingSuccess: ({ jobId }: { jobId: string }) => {
    try {
      Mixpanel.track('job_interview_booking_success', { jobId })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobSave: ({
    type,
    company,
    jobCardSource,
    jobUrl,
    jobName,
    jobFullAddress,
    jobCityAndState,
  }: MixpanelJobProperties) => {
    try {
      Mixpanel.track('job_save', {
        type,
        company,
        job_card_source: jobCardSource,
        job_url: jobUrl,
        job_name: jobName,
        job_full_address: jobFullAddress,
        job_city_and_state: jobCityAndState,
        job_saved: null, // No need for job saved property here.
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobUnsave: ({
    type,
    company,
    jobCardSource,
    jobUrl,
    jobName,
    jobFullAddress,
    jobCityAndState,
  }: MixpanelJobProperties) => {
    try {
      Mixpanel.track('job_unsave', {
        type,
        company,
        job_card_source: jobCardSource,
        job_url: jobUrl,
        job_name: jobName,
        job_full_address: jobFullAddress,
        job_city_and_state: jobCityAndState,
        job_saved: null, // No need for job saved property here.
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobsSearch: ({
    query,
    location,
    distance,
  }: {
    query?: string
    location?: string
    distance?: number | null
  }) => {
    try {
      Mixpanel.track('jobs_search', {
        query: query || null,
        location: location || null,
        distance: distance || null,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobsSwiperJobsLinkClick: ({
    jobCardSource,
    jobsLinkText,
  }: {
    jobCardSource: JobCardSource
    jobsLinkText?: string
  }) => {
    try {
      Mixpanel.track('jobs_swiper_jobs_link_click', {
        job_card_source: jobCardSource,
        jobs_link_text: jobsLinkText || null,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackJobsSwiperSwipe: ({
    direction,
    jobCardSource,
    screenSize,
    swipeType,
  }: {
    direction: 'next' | 'prev'
    jobCardSource: JobCardSource
    screenSize: ScreenSize
    swipeType: 'button' | 'physical'
  }) => {
    try {
      Mixpanel.track('jobs_swiper_swipe', {
        direction,
        job_card_source: jobCardSource,
        screen_size: screenSize,
        swipe_type: swipeType,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackInternalJobApplicationResumeUploadSuccess: ({
    jobId,
  }: {
    jobId: string
  }) => {
    try {
      Mixpanel.track('internal_job_application_resume_upload_success', {
        jobId,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackInternalJobApplicationStep: ({
    jobId,
    step,
  }: {
    jobId: string
    step: number
  }) => {
    try {
      Mixpanel.track(`internal_job_application_step_${step}`, {
        job_id: jobId,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackInternalJobApplicationSuccess: ({ jobId }: { jobId: string }) => {
    try {
      Mixpanel.track('internal_job_application_success', {
        job_id: jobId,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackOnboardingStep: (step: number) => {
    try {
      Mixpanel.track(`onboarding_step_${step}`)
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackOnboardingSuccess: (signupVariant: SignupVariant | null) => {
    try {
      Mixpanel.track('onboarding_success', {
        signup_variant: signupVariant,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackShowFullJobDescriptionClick: ({
    type,
    company,
    jobCardSource,
    jobUrl,
    jobName,
    jobFullAddress,
    jobCityAndState,
    jobSaved,
  }: MixpanelJobProperties) => {
    try {
      Mixpanel.track('show_full_job_description_click', {
        type,
        company,
        job_card_source: jobCardSource,
        job_url: jobUrl,
        job_name: jobName,
        job_full_address: jobFullAddress,
        job_city_and_state: jobCityAndState,
        job_saved: jobSaved,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackTalkToAnExpertClick: () => {
    try {
      Mixpanel.track('talk_to_an_expert_click')
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackTalkToAnExpertBookingSuccess: () => {
    try {
      Mixpanel.track('talk_to_an_expert_booking_success')
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackUserRevisitingInternalJobApplicationStep: ({
    jobId,
    step,
  }: {
    jobId: string
    step: number
  }) => {
    try {
      Mixpanel.track(`user_revisiting_internal_job_application_step_${step}`, {
        job_id: jobId,
      })
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  trackUserRevisitingOnboardingStep: (step: number) => {
    try {
      Mixpanel.track(`user_revisiting_onboarding_step_${step}`)
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },

  updateMixpanelProfile: (properties: Dict) => {
    try {
      Mixpanel.people.set(properties)
    } catch (error) {
      console.error('Mixpanel error:', error)
    }
  },
}
