import React from 'react'

import { FieldValues, Path, Controller, PathValue } from 'react-hook-form'

import { Textarea, TextareaProps, Message } from 'src/atoms'

import { useFormContext } from './form'

export type RHFTextareaProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  clearErrorOnChange?: boolean
  clearWarningOnChange?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => boolean | void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => boolean | void
} & Omit<TextareaProps, 'value' | 'onChange' | 'onBlur'>

export const RHFTextarea = <TFieldValues extends FieldValues>({
  name,
  description,
  onChange: handleChangeProps,
  onBlur: handleBlurProps,
  clearErrorOnChange,
  clearWarningOnChange,
  ...props
}: RHFTextareaProps<TFieldValues>) => {
  const { control, clearErrors, setValue, warnings, clearWarning } =
    useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange: handleChangeField,
          onBlur: handleBlurField,
          ...field
        },
        fieldState: { error },
      }) => {
        const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
          e
        ) => {
          const stopPropagation = handleChangeProps?.(e)
          if (stopPropagation) {
            return
          }
          if (clearWarningOnChange) {
            clearWarning(name)
          }
          if (clearErrorOnChange) {
            clearErrors(name)
            setValue(
              name,
              e.target.value as PathValue<TFieldValues, Path<TFieldValues>>
            )
            return
          }
          handleChangeField(e)
        }

        const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (
          e
        ) => {
          const stopPropagation = handleBlurProps?.(e)
          if (stopPropagation) {
            return
          }
          handleBlurField()
        }

        const messageLabel = error?.message || warnings[name]
        const messageIntent = error?.message ? 'error' : 'warning'
        return (
          <>
            <Textarea
              onChange={handleChange}
              onBlur={handleBlur}
              {...field}
              {...props}
            />
            {description}
            <Message
              className="ms-1.5"
              label={messageLabel}
              intent={messageIntent}
            />
          </>
        )
      }}
    />
  )
}
