import { JobSearchSchema } from 'src/schemas'
import { SigninMethod } from 'src/types'

import { defaultJobSearchQuery } from './constants'

const canVerifyPhoneStorageKey = 'showPhoneVerification'
const appVersionDataStorageKey = 'appVersionData'
const jobSearchQueryStorageKey = 'jobSearchQuery'
const signinMethodPreferenceStorageKey = 'signinMethod'
export const phoneVerificationCountStorageKey = 'phoneVerificationCount'

const unusedKeys = [
  'test',
  'isFromLoginPage',
  'previousPathname',
  'hasRefreshed',
  'searchQuery',
  'open-resume-state',
]

type AppVersionData = {
  version: string
  hasRefreshed: boolean
}

const setAppVersionData = (
  webAppVersionData: AppVersionData
): AppVersionData => {
  localStorage.setItem(
    appVersionDataStorageKey,
    JSON.stringify(webAppVersionData)
  )
  return webAppVersionData
}

const getAppVersionData = (apiVersion: string): AppVersionData | null => {
  const data = localStorage.getItem(appVersionDataStorageKey)

  const appVersionData: AppVersionData | null = data
    ? (JSON.parse(data) as AppVersionData)
    : null

  return appVersionData?.version === apiVersion ? appVersionData : null
}

export const storage = {
  // ! Assuming there is only one api version deployed and its always the latest
  // ! initialize/overwrite the localstorage version to api version
  hasAlreadyRefreshedAppVersion: (version: string): boolean => {
    const appVersionData = getAppVersionData(version)
    return appVersionData
      ? appVersionData.hasRefreshed
      : setAppVersionData({
          version,
          hasRefreshed: false,
        }).hasRefreshed
  },

  // ! Assuming there is only one api version deployed and its always the latest
  // ! initialize/overwrite the localstorage version to api version
  setAppVersionHasRefreshed: (version: string): void => {
    setAppVersionData({
      version,
      hasRefreshed: true,
    })
  },

  setJobSearchQuery: (jobSearchQuery: JobSearchSchema): void => {
    localStorage.setItem(
      jobSearchQueryStorageKey,
      JSON.stringify(jobSearchQuery)
    )
  },
  getJobSearchQuery: (): JobSearchSchema | null => {
    try {
      const data = localStorage.getItem(jobSearchQueryStorageKey)
      if (data) {
        return JSON.parse(data) as JobSearchSchema
      }
      return null
    } catch (error) {
      console.error(error)
    }
    return defaultJobSearchQuery
  },

  getSigninMethod: (): SigninMethod => {
    return (
      (localStorage.getItem(
        signinMethodPreferenceStorageKey
      ) as SigninMethod) || SigninMethod.phone
    )
  },

  setSigninMethod: (signinMethod: SigninMethod) => {
    localStorage.setItem(signinMethodPreferenceStorageKey, signinMethod)
  },

  getPhoneVerificationCount: (): number => {
    return (
      parseInt(
        sessionStorage.getItem(phoneVerificationCountStorageKey) || '0'
      ) || 0
    )
  },

  setPhoneVerificationCount: (count: number): void => {
    sessionStorage.setItem(phoneVerificationCountStorageKey, count.toString())
  },

  clearPhoneVerificationCount: (): void => {
    sessionStorage.removeItem(phoneVerificationCountStorageKey)
  },

  getCanVerifyPhone: (): boolean => {
    return localStorage.getItem(canVerifyPhoneStorageKey) === 'true'
  },

  setCanVerifyPhone: (show: boolean): void => {
    localStorage.setItem(canVerifyPhoneStorageKey, show.toString())
  },

  clearUnusedKeys: () => {
    unusedKeys.forEach((unusedKey) => localStorage.removeItem(unusedKey))
  },

  clearAll: () => {
    localStorage.clear()
    sessionStorage.clear()
  },
}
