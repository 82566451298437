import React, { useCallback } from 'react'

import { FieldValues, Path, PathValue, Controller } from 'react-hook-form'

import { SelectYear, SelectYearProps, Message } from 'src/atoms'

import { useFormContext } from './form'

export type RHFSelectYearProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  clearErrorOnChange?: boolean
  clearWarningOnChange?: boolean
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => boolean | void
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => boolean | void
} & Omit<SelectYearProps, 'value' | 'name' | 'onChange' | 'onBlur'>

export const RHFSelectYear = <TFieldValues extends FieldValues>({
  name,
  description,
  onChange: handleChangeProps,
  onBlur: handleBlurProps,
  clearErrorOnChange,
  clearWarningOnChange,
  ...props
}: RHFSelectYearProps<TFieldValues>) => {
  const { control, setValue, clearErrors, clearWarning, warnings } =
    useFormContext<TFieldValues>()

  const handleClear = useCallback(() => {
    const val = '' as PathValue<TFieldValues, Path<TFieldValues>>
    setValue(name, val, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
    handleChangeProps?.(val)
  }, [name, setValue, handleChangeProps])

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange: handleChangeField,
          onBlur: handleBlurField,
          ...field
        },
        fieldState: { error },
      }) => {
        const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (
          e
        ) => {
          const stopPropagation = handleChangeProps?.(e)
          if (stopPropagation) {
            return
          }
          if (clearWarningOnChange) {
            clearWarning(name)
          }
          if (clearErrorOnChange) {
            clearErrors(name)
            setValue(
              name,
              e.target.value as PathValue<TFieldValues, Path<TFieldValues>>
            )
            return
          }
          handleChangeField(e)
        }

        const handleBlur: React.FocusEventHandler<HTMLSelectElement> = (e) => {
          const stopPropagation = handleBlurProps?.(e)
          if (stopPropagation) {
            return
          }
          handleBlurField()
        }

        const messageLabel = error?.message || warnings[name]
        const messageIntent = error?.message ? 'error' : 'warning'
        return (
          <>
            <SelectYear
              {...field}
              {...props}
              onChange={handleChange}
              onBlur={handleBlur}
              onClear={handleClear}
            />
            {description}
            <Message
              className="ms-1.5"
              label={messageLabel}
              intent={messageIntent}
            />
          </>
        )
      }}
    />
  )
}
