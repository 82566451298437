import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { cva } from 'class-variance-authority'
import clsx from 'clsx'
import debounce from 'debounce'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Select, {
  ActionMeta,
  components,
  ControlProps,
  GroupBase,
  InputActionMeta,
  InputProps,
  MenuProps,
  OptionProps,
  ContainerProps as SelectContainerProps,
  Props as SelectProps,
  SingleValue as SingleValueOptionType,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select'
import SelectBaseProps from 'react-select/base'

import { env } from 'src/lib/env'

import { reactSelectVariants } from '../reactSelectVariants'
import { Spinner } from '../Spinner'
import { Option as BaseOption } from '../types'

const googleMapsApiKey = env.googleMapsApiKey
const debounceWait = process.env.NODE_ENV === 'production' ? 128 : 256

const extractCityFromAddress = async (address: string): Promise<string> => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${googleMapsApiKey}`

  try {
    const response = await fetch(url)
    const data = await response.json()

    if (data.status === 'OK' && data.results.length > 0) {
      const addressComponents = data.results[0].address_components
      const locality = addressComponents.find((component) =>
        component.types.includes('locality')
      )
      const administrativeArea = addressComponents.find((component) =>
        component.types.includes('administrative_area_level_1')
      )
      // const country = addressComponents.find((component) =>
      //   component.types.includes('country')
      // )
      const country = 'USA'

      if (locality && administrativeArea && country) {
        return `${locality.long_name}, ${administrativeArea.short_name}, ${country}`
      }
    }
  } catch (err) {
    console.error(err)
  }
  return ''
}

const delay = 1024

const extractCityFromAddressWithDelay = async (
  address?: string
): Promise<string> => {
  if (!address) return ''
  const fetchPromise = extractCityFromAddress(address)
  const delayPromise = new Promise((resolve) => setTimeout(resolve, delay))
  const [data] = await Promise.all([fetchPromise, delayPromise])
  return data
}

const SelectSpinner = () => (
  <Spinner className="mx-2 size-5 stroke-primary-700" />
)

type Option = BaseOption<string, string>

const controlVariants = cva(
  'flex items-center h-full justify-between relative w-full',
  {
    variants: {
      background: {
        white: 'bg-white',
        primary: 'bg-primary-300',
        transparent: '',
      },
      bordered: {
        true: 'border shadow-sm after:pointer-events-none after:absolute after:rounded-lg after:-inset-[1px] after:ring-inset after:focus-within:ring-2 after:focus-within:ring-primary-700',
        false: '',
      },
      rounded: {
        true: 'rounded-lg',
        false: '',
      },
      isFocused: {
        true: '',
        false: '',
      },
      isDisabled: {
        true: '!bg-gray-50 !border-gray-100',
        false: 'cursor-text',
      },
      size: {
        sm: 'min-h-7',
        lg: 'min-h-9',
        xl: 'min-h-10',
      },
    },
    compoundVariants: [
      {
        bordered: true,
        background: 'white',
        className: 'border-gray-500',
      },
      {
        bordered: true,
        background: 'primary',
        className: 'border-primary-700',
      },
    ],
  }
)

const Control = ({
  className,
  children,
  ...props
}: ControlProps<Option, false, GroupBase<Option>>) => (
  <components.Control
    className={clsx(
      controlVariants({
        background: props.selectProps.background,
        bordered: props.selectProps.bordered,
        rounded: props.selectProps.rounded,
        isFocused: props.isFocused,
        isDisabled: props.isDisabled,
        size: props.selectProps.size,
      }),
      className
    )}
    {...props}
  >
    {props.selectProps.startIcon}
    {children}
    {props.selectProps.loading && <SelectSpinner />}
  </components.Control>
)

const DropdownIndicator = () => null

const IndicatorSeparator = () => null

const Input = ({
  className,
  ...props
}: InputProps<Option, false, GroupBase<Option>>) => (
  <components.Input className={clsx('px-3', className)} {...props} />
)

const Menu = ({
  className,
  ...props
}: MenuProps<Option, false, GroupBase<Option>>) =>
  props.options.length ? (
    <components.Menu
      className={clsx(
        'absolute top-full z-10 my-1 w-full rounded-lg bg-white drop-shadow-xl',
        className
      )}
      {...props}
    />
  ) : null

const optionVariants = cva(
  'min-h-9 py-2 px-3 select-none hover:bg-primary-200 !flex items-center w-full',
  {
    variants: {
      isFocused: {
        true: 'bg-primary-200',
        false: '',
      },
      isSelected: {
        true: '!bg-primary-700 text-white',
        false: '',
      },
    },
  }
)

const Option = ({
  className,
  ...props
}: OptionProps<Option, false, GroupBase<Option>>) => (
  <components.Option
    className={clsx(
      optionVariants({
        isFocused: props.isFocused,
        isSelected: props.isSelected,
      }),
      className
    )}
    {...props}
  />
)

const selectContainerVariants = cva('w-full relative', {
  variants: {
    size: {
      sm: 'font-semibold text-sm min-h-7',
      lg: 'font-medium text-md min-h-9',
      xl: 'font-medium text-md min-h-10',
    },
    isDisabled: {
      true: 'text-gray-700 !pointer-events-auto cursor-not-allowed',
      false: 'text-gray-950',
    },
  },
})

const SelectContainer = ({
  className,
  innerProps,
  ...props
}: SelectContainerProps<Option, false, GroupBase<Option>>) => (
  <components.SelectContainer
    className={clsx(
      selectContainerVariants({
        size: props.selectProps.size,
        isDisabled: props.isDisabled,
      }),
      className
    )}
    // @ts-expect-error: this allows styling react-select from Fieldset/Fieldgroup
    // its the recommended way of adding custom data attributes to react-select
    // but didn't look into extending innerProps type
    innerProps={{ ...innerProps, 'data-slot': 'control' }}
    {...props}
  />
)

const SingleValue = ({
  className,
  ...props
}: SingleValueProps<Option, false, GroupBase<Option>>) => (
  <components.SingleValue className={clsx('px-3', className)} {...props} />
)

const ValueContainer = ({
  className,
  ...props
}: ValueContainerProps<Option, false, GroupBase<Option>>) => (
  <components.ValueContainer className={clsx('gap-2', className)} {...props} />
)

export type CitySelectProps = Omit<
  SelectProps<Option, false, GroupBase<Option>>,
  'onChange' | 'value' | 'options' | 'onBlur'
> & {
  value?: string
  onChange: (value?: string) => boolean | void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => boolean | void
}

export const CitySelect = React.forwardRef(
  (
    {
      name,
      startIcon,
      value,
      onChange,
      onInputChange,
      styles,
      classNames,
      className,
      isMulti,
      components,
      // custom props
      rounded = true,
      bordered = true,
      loading: parentLoading,
      background = 'white',
      size = 'lg',
      ...props
    }: CitySelectProps,
    ref: React.Ref<SelectBaseProps<Option, false, GroupBase<Option>>>
  ) => {
    const [options, setOptions] = useState<Option[]>([])
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(parentLoading || false)

    useEffect(() => {
      setLoading(parentLoading || false)
    }, [parentLoading])

    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
      usePlacesService({
        apiKey: googleMapsApiKey,
      })

    const getPlacePredictionsDebounced = useMemo(
      () =>
        debounce((newValue: string) => {
          getPlacePredictions({
            input: newValue,
            types: ['(cities)'],
            componentRestrictions: { country: 'us' },
          })
        }, debounceWait),
      [getPlacePredictions]
    )

    const handleInputChange = useCallback(
      (newValue: string, actionMeta: InputActionMeta) => {
        setInputValue(newValue)
        onInputChange?.(newValue, actionMeta)
        getPlacePredictionsDebounced(newValue)
      },
      [getPlacePredictionsDebounced, onInputChange]
    )

    const handleChange = useCallback(
      (
        newOption: SingleValueOptionType<Option>,
        actionMetadata: ActionMeta<Option>
      ) => {
        if (actionMetadata.action === 'clear') {
          setOptions([])
          return onChange()
        }
        if (actionMetadata.action === 'select-option' && newOption) {
          onChange(newOption.value)
        }
      },
      [onChange]
    )

    const refetchPlacePredictionsTimeoutIdRef = useRef<
      ReturnType<typeof setTimeout> | undefined
    >()

    useEffect(() => {
      clearTimeout(refetchPlacePredictionsTimeoutIdRef?.current)
      if (
        inputValue &&
        !isPlacePredictionsLoading &&
        !placePredictions.length
      ) {
        refetchPlacePredictionsTimeoutIdRef.current = setTimeout(() =>
          getPlacePredictions({
            input: inputValue,
            componentRestrictions: { country: 'us' },
          })
        )
      }

      return () => clearTimeout(refetchPlacePredictionsTimeoutIdRef.current)
    }, [
      getPlacePredictions,
      inputValue,
      isPlacePredictionsLoading,
      placePredictions.length,
    ])

    useEffect(() => {
      const newOptions: Option[] =
        placePredictions?.map?.((prediction) => ({
          label: prediction?.description,
          value: prediction?.description,
        })) || []

      let optionsHaveChanged = false
      const existingLabels = options.map((option) => option.label)
      newOptions.forEach((option) => {
        if (!existingLabels.includes(option.label)) {
          optionsHaveChanged = true
        }
      })
      if (optionsHaveChanged) {
        setOptions(newOptions)
      }
    }, [placePredictions, options])

    const previousValueRef = useRef<string | undefined>()
    useEffect(() => {
      if (!value || previousValueRef.current === value) return
      previousValueRef.current = value
      const selectedOption = options.find((option) => option.value === value)
      // Anytime we don't find an option, create it and select it
      // this enables passing initial/default values to this component
      if (!selectedOption) {
        setOptions([{ value, label: value }])
      }
    }, [options, value])

    const selectedOption = useMemo(() => {
      return options.find((option) => option.value === value)
    }, [options, value])

    const handleNonCityValue = useCallback(
      async (option?: Option) => {
        const value = option?.value
        if (!value) {
          return
        }
        const locationComponents = option.value.split(',') || []
        if (locationComponents.length <= 3) {
          return
        }
        setLoading(true)
        const extractedCity = await extractCityFromAddressWithDelay(value)
        if (!extractedCity) {
          return
        }
        setOptions((currentOptions) => [
          ...currentOptions,
          { value: extractedCity, label: extractedCity },
        ])
        onChange(extractedCity)
        setLoading(false)
      },
      [onChange]
    )

    useEffect(() => {
      handleNonCityValue(selectedOption)
    }, [handleNonCityValue, selectedOption])

    return (
      <Select<Option, false>
        {...props}
        ref={ref}
        className={clsx(
          // this doesn't apply any classnames but can potentially
          reactSelectVariants({
            background,
            bordered,
            rounded,
            size,
          }),
          className
        )}
        name={name}
        isDisabled={props.isDisabled || loading}
        isSearchable
        isMulti={isMulti}
        unstyled
        onInputChange={handleInputChange}
        onChange={handleChange}
        options={options}
        value={selectedOption}
        inputValue={inputValue}
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator,
          Input,
          Menu,
          Option,
          SelectContainer,
          SingleValue,
          ValueContainer,
          ...components,
        }}
        styles={{
          control: () => ({}),
          option: () => ({}),
          ...styles,
        }}
        classNames={{
          placeholder: () => 'text-gray-700 px-3',
          noOptionsMessage: () => 'hidden',
          indicatorsContainer: () => 'me-1.5',
          ...classNames,
        }}
        // custom props
        startIcon={startIcon}
        loading={loading}
        background={background}
        bordered={bordered}
        rounded={rounded}
        size={size}
      />
    )
  }
)
