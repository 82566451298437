import { UsersIcon } from '@heroicons/react/24/solid'
import { cva, type VariantProps } from 'class-variance-authority'

import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  BadgeColor,
  Badge,
  Button,
  Input,
  Text,
  CopyTextButton,
} from 'src/atoms'
import { useAuth } from 'src/auth'
import { Shimmer } from 'src/components/Shimmer/Shimmer'
import { useScreenSize } from 'src/hooks/useResize'
import { logError } from 'src/lib/utils'

const linkCopySuccessMessage = 'Link copied to clipboard!'

const referralContentContainer = cva(
  'flex flex-col md:flex-row justify-between',
  {
    variants: {
      intent: {
        light: ['bg-white'],
        dark: ['bg-gray-950'],
      },
    },
    defaultVariants: {
      intent: 'light',
    },
  }
)

const referralContentHeaderText = cva('text-xl md:text-3xl', {
  variants: {
    intent: {
      light: ['text-gray-950'],
      dark: ['text-gray-50'],
    },
  },
  defaultVariants: {
    intent: 'light',
  },
})

const referralContentHeaderDescription = cva('text-sm md:text-lg', {
  variants: {
    intent: {
      light: ['text-gray-900'],
      dark: ['text-gray-100'],
    },
  },
  defaultVariants: {
    intent: 'light',
  },
})

const FETCH_REFERRAL_HASH_AND_NAME = gql`
  query ReferralHashAndNameByUserId($userId: Int!) {
    referralHashByUserId(userId: $userId)
    user(id: $userId) {
      profile {
        firstName
      }
    }
  }
`

export default function ReferralContent(
  props: VariantProps<typeof referralContentContainer>
) {
  const { currentUser } = useAuth()
  const { data, loading, error } = useQuery(FETCH_REFERRAL_HASH_AND_NAME, {
    variables: { userId: currentUser?.id },
    skip: !currentUser?.id,
    fetchPolicy: 'cache-and-network',
  })
  const disabled = loading || !!error

  const screenSize = useScreenSize()
  const referralHeaderText =
    screenSize === 'sm'
      ? 'Refer Friends, Earn $1,000'
      : 'Refer Friends, Earn $1,000 with Laborup'
  const referralLink = data?.referralHashByUserId
    ? `https://app.laborup.com/signup?code=${data.referralHashByUserId}`
    : 'https://app.laborup.com/signup'

  const handleShareLink = async () => {
    const userName = data?.user?.profile?.firstName || 'Your friend'

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Join Laborup',
          text: `${userName} invites you to join Laborup, the platform connecting skilled professionals with high-paying manufacturing jobs. Create your free profile in minutes and start exploring opportunities tailored to your expertise.`,
          url: referralLink,
        })
      } catch (error) {
        logError(error)
      }
    } else {
      await navigator.clipboard.writeText(referralLink)
      toast.success('Link copied to clipboard!')
    }
  }

  return (
    <div className={referralContentContainer({ intent: props.intent })}>
      <div className="flex flex-col items-center text-center md:text-left md:items-start p-3 gap-2">
        {props.intent === 'dark' ? (
          <UsersIcon className="text-white w-6 h-6" />
        ) : (
          <Badge color={BadgeColor.green}>
            <UsersIcon className="text-green-950 w-3 h-3" />
            Referral Sweepstakes
          </Badge>
        )}
        <div>
          <Text
            weight="bold"
            className={referralContentHeaderText({ intent: props.intent })}
          >
            {referralHeaderText}
          </Text>
          <Text
            className={referralContentHeaderDescription({
              intent: props.intent,
            })}
          >
            Get a $1,000 referral bonus when we place someone you refer to one
            of our exclusive jobs!
          </Text>
        </div>
      </div>
      <div className="min-w-80 md:min-w-96 hidden md:flex justify-center flex-col gap-3">
        {!disabled ? (
          <Input
            disabled
            value={
              data?.referralHashByUserId
                ? `https://app.laborup.com/signup?code=${data.referralHashByUserId}`
                : 'https://app.laborup.com/signup'
            }
          />
        ) : error ? (
          <Text weight="semibold" className="text-md text-gray-900">
            Error fetching referral link!
          </Text>
        ) : (
          <Shimmer className="h-9" />
        )}
        <CopyTextButton
          disabled={disabled}
          successMessage={linkCopySuccessMessage}
          text={referralLink}
        >
          Copy referral link
        </CopyTextButton>
      </div>
      <div className="md:min-w-80 flex md:hidden gap-3">
        <div className="bg-white flex grow shrink-0 rounded-lg">
          <CopyTextButton
            className="grow"
            color="white"
            disabled={disabled}
            successMessage={linkCopySuccessMessage}
            text={referralLink}
          >
            Copy referral link
          </CopyTextButton>
        </div>
        <Button
          disabled={disabled}
          className="grow shrink-0"
          onClick={handleShareLink}
        >
          Share referral link
        </Button>
      </div>
    </div>
  )
}
