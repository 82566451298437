import React from 'react'

import { FieldValues, Path, Controller, PathValue } from 'react-hook-form'

import { AutocompleteInput, AutocompleteInputProps, Message } from 'src/atoms'

import { useFormContext } from './form'

export type RHFAutocompleteInputProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  onChange?: (value?: string) => boolean | void
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => boolean | void
  clearErrorOnChange?: boolean
  clearWarningOnChange?: boolean
} & Omit<AutocompleteInputProps, 'value' | 'onChange' | 'onBlur'>

export const RHFAutocompleteInput = <TFieldValues extends FieldValues>({
  name,
  description,
  onChange: handleChangeProps,
  onBlur: handleBlurProps,
  clearErrorOnChange,
  clearWarningOnChange,
  ...props
}: RHFAutocompleteInputProps<TFieldValues>) => {
  const { control, clearErrors, setValue, warnings, clearWarning } =
    useFormContext<TFieldValues>()

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({
          field: {
            onChange: handleChangeField,
            onBlur: handleBlurField,
            ...field
          },
          fieldState: { error },
        }) => {
          const handleChange = (val?: string) => {
            const stopPropagation = handleChangeProps?.(val)
            if (stopPropagation) {
              return
            }
            if (clearWarningOnChange) {
              clearWarning(name)
            }
            if (clearErrorOnChange) {
              clearErrors(name)
              setValue(name, val as PathValue<TFieldValues, Path<TFieldValues>>)
              return
            }
            handleChangeField(val)
          }

          const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
            const stopPropagation = handleBlurProps?.(e)
            if (stopPropagation) {
              return
            }
            handleBlurField()
          }

          const messageLabel = error?.message || warnings[name]
          const messageIntent = error?.message ? 'error' : 'warning'

          return (
            <>
              <AutocompleteInput
                onChange={handleChange}
                onBlur={handleBlur}
                {...field}
                {...props}
              />
              {description}
              <Message
                className="ms-1.5"
                label={messageLabel}
                intent={messageIntent}
              />
            </>
          )
        }}
      />
    </>
  )
}
