// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { PrivateSet, Set, Router, Route } from '@redwoodjs/router'

import AccountLayout from 'src/layouts/AccountLayout/AccountLayout'
import AdditionalMobileNavigationLayout from 'src/layouts/AdditionalMobileNavigationLayout/AdditionalMobileNavigationLayout'
import AuthenticatedLayout from 'src/layouts/AuthenticatedLayout/AuthenticatedLayout'
import CampaignLandingPageLayout from 'src/layouts/CampaignLandingPageLayout/CampaignLandingPageLayout'
import GoogleAnalyticsPageViewLayout from 'src/layouts/GoogleAnalyticsPageViewLayout/GoogleAnalyticsPageViewLayout'
import SigninSignupLayout from 'src/layouts/SigninSignupLayout/SigninSignupLayout'
import SkinnyFormLayout from 'src/layouts/SkinnyFormLayout/SkinnyFormLayout'
import UserLayout from 'src/layouts/UserLayout/UserLayout'

import { useAuth } from './auth'

// !Important: Update web/types/routerRoutes.d.ts whenever you change routes

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Set wrap={GoogleAnalyticsPageViewLayout}>
        <Route notfound page={NotFoundPage} />
        <Set wrap={CampaignLandingPageLayout}>
          <Route path="/certifications" page={AceCncMachiningRedirectPage} name="aceCncMachiningRedirect" />
          <Route path="/endorsements-success-story" page={EndorsementsSuccessStoryPage} name="endorsementsSuccessStory" />
        </Set>
        <Set wrap={SigninSignupLayout}>
          <Route path="/signup" page={AuthSignupPage} name="signup" />
          <Route path="/login" page={AuthLoginPage} name="login" />
          <Route path="/access/{adminOnboardHash}" page={AuthSignupPage} name="access" />
          <Route path="/e/{endorsementHash}" redirect="signup" />
        </Set>
        <PrivateSet wrap={AuthenticatedLayout} unauthenticated="login" roles={['user']}>
          <Set wrap={UserLayout}>
            <Route path="/" page={HomePage} name="home" />
            <Route path="/jobs/matched" page={JobMatchedJobsPage} name="matchedJobs" />
            {/* as of 11/05/2024 we still had 5 events in mixpanel that hit /jobs/recommended for the last 3 months */}
            <Route path="/jobs/recommended" redirect="matchedJobs" />
            <Route path="/jobs/saved" page={JobSavedJobsPage} name="savedJobs" />
            <Route path="/jobs/search" page={JobJobsSearchPage} name="searchJobs" />
            <Route path="/laborup-jobs" page={JobLaborupJobsPage} name="laborupJobs" />
            <Route path="/laborup-jobs/{id}" page={JobLaborupJobsPage} name="laborupJob" />
            <Route path="/documents/cover-letters/upload" page={CoverLettersUploadPage} name="coverLettersUpload" />
            <Route path="/documents/supporting-documents/upload" page={DocumentsUploadPage} name="documentsUpload" />
            <Route path="/resume-builder/resumes/{id:Int}/edit" page={ResumeFileEditResumeFilePage} name="editResume" />
            <Route path="/upskilling/search" page={UpskillingUpskillingSearchPage} name="upskilling" />
            <Route path="/salary" page={SalaryPage} name="salary" />
            <Route path="/account/profile/image/edit" page={AvatarFileEditAvatarFilePage} name="editAvatar" />

            <Set wrap={AdditionalMobileNavigationLayout}>
              <Route path="/documents" page={ResumesPage} name="resumes" />
              <Route path="/documents/resumes/new" page={ResumeFileNewResumeFilePage} name="newResume" />
              <Route path="/documents/resumes/upload" page={ResumeFileResumesUploadPage} name="resumesUpload" />
              <Set wrap={AccountLayout}>
                <Route path="/account" page={AccountPage} name="profile" />
              </Set>
              <Set wrap={SkinnyFormLayout}>
                <Route path="/profile/educations/new" page={EducationNewEducationPage} name="newEducation" />
                <Route path="/profile/educations/{id:Int}/edit" page={EducationEditEducationPage} name="editEducation" />
                <Route path="/profile/certifications/new" page={CertificationNewCertificationPage} name="newCertification" />
                <Route path="/profile/certifications/{id:Int}/edit" page={CertificationEditCertificationPage} name="editCertification" />
                <Route path="/profile/work-experiences/{id:Int}/edit" page={WorkExperienceEditWorkExperiencePage} name="editWorkExperience" />
                <Route path="/profile/work-experiences/new" page={WorkExperienceNewWorkExperiencePage} name="newWorkExperience" />
                <Route path="/profile/skills/edit" page={SkillSkillsPage} name="editSkills" />
                <Route path="/profile/basic-info/edit" page={EditProfilePage} name="editBasicInfo" />
                <Route path="/profile/industries/edit" page={IndustryIndustryPage} name="editIndustries" />
                <Route path="/profile/endorsements/new" page={NewEndorsementPage} name="newEndorsement" />
                <Route path="/profile/endorsements/{id}" page={EndorsementPage} name="endorsement" />
                <Route path="/account/notification-preferences" page={EditNotificationPreferencesPage} name="notificationPreferences" />
              </Set>
            </Set>
          </Set>
        </PrivateSet>
      </Set>
    </Router>
  )
}

export default Routes
